<template>
  <div class="assignment">
    <div class="flex flex-center">
      <div class="assignment-usernum flex">
        <div style="width: 50%" class="flex items-center flex-center">
          <div class="assignment-usernum-text">
            邀请注册实名总用户数<span class="assignment-usernum-text-span">
              {{ number.signCount }} </span
            >。
          </div>
          <div class="assignment-usernum-button" @click="mySubordinates(1)">
            查看
          </div>
        </div>
        <div style="width: 50%" class="flex items-center flex-center">
          <div class="assignment-usernum-text">
            邀请购买通行证总用户数<span class="assignment-usernum-text-span">
              {{ number.orderCount }} </span
            >。
          </div>
          <div class="assignment-usernum-button" @click="mySubordinates(2)">
            查看
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-center" style="margin-top: 20px">
      <div class="assignment-bac1">
        <div>
          <div class="assignment-bac1-text">您的邀请码</div>
          <div class="flex assignment-bac1-text-top">
            <div class="assignment-bac1-text-top-text">
              <div>{{ username.userId }}</div>
              <div class="assignment-duplication flex items-center flex-center">
                <img
                  class="assignment-bac1-text-top-text-img"
                  src="../../../img/home/duplication.png"
                  alt=""
                  @click="copyTextButton(username.userId, '邀请码')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="assignment-bac">
        <template v-if="!superiors">
          <div style="color: #ffffff; font-size: 24px">绑定邀请码</div>
          <div class="flex" style="margin-top: 22px">
            <div class="assignment-bac-input">
              <el-input
                v-model="superiorUserId"
                placeholder="请输入邀请码"
              ></el-input>
            </div>
            <div class="assignment-bac-buton" @click="confirmAddition">
              确认
            </div>
          </div>
        </template>
        <template v-else>
          <div style="color: #ffffff; font-size: 24px">绑定邀请码</div>
          <div class="flex">
            <div class="bound-title">已绑定上级uid:</div>
            <div class="bound-text">
              {{ superiorUserId }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="lashin-title1">战友招募</div>

    <div class="assignment-ul">
      <div
        v-for="(item, index) in inviteTask"
        :key="index"
        class="assignment-ul-li flex items-center flex-around"
      >
        <div>
          <div style="color: #ffffff; font-size: 24px">
            邀请<span
              style="
                color: #daff00;
                text-shadow: 2px 3px 2px #74077d;
                font-size: 24px;
              "
              >{{ item.inviteTask.signCount }}</span
            >位用户注册实名。
          </div>
          <div class="assignment-ul-li-progressbar">
            <el-progress
              :percentage="percentage(item.inviteTask.signCount)"
              :stroke-width="30"
              text-inside
              color="#9757C5"
              define-back-color="#811C91"
              text-color="#fff"
              :format="setItemText(item.inviteTask.signCount)"
            ></el-progress>
          </div>
        </div>
        <div>
          <div style="color: #ffffff; font-size: 24px">
            邀请<span
              style="
                color: #daff00;
                text-shadow: 2px 3px 2px #74077d;
                font-size: 24px;
              "
              >{{ item.inviteTask.orderCount }}</span
            >用户购买通行证。
          </div>
          <div class="assignment-ul-li-progressbar">
            <el-progress
              :percentage="percentage1(item.inviteTask.orderCount)"
              :stroke-width="30"
              text-inside
              color="#9757C5"
              define-back-color="#811C91"
              text-color="#fff"
              :format="setItemText1(item.inviteTask.orderCount)"
            ></el-progress>
          </div>
        </div>
        <div
          class="assignment-ul-li-border flex flex-direction items-center flex-center"
        >
          <img
            src="https://cdn.bubbleplan.cn/static/journalism/chip.png"
            class="assignment-ul-li-border-img"
          />
          <div class="assignment-ul-li-border-text">
            <span style="font-weight: 700">{{ item.inviteTask.rewardNum }}</span
            >芯片
          </div>
        </div>
        <div>
          <div
            :class="
              breakShow ? 'assignment-ul-li-button1' : 'assignment-ul-li-button'
            "
            @click="drawdown(item)"
          >
            {{ item.isGet == 1 ? "已领取" : "领取奖励" }}
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        margin-top: 50px;
        padding-bottom: 80px;
        text-align: center;
        color: #ffffff;
        font-size: 26px;
      "
    >
      此活动任务，不可叠加，四选一提交。一经领取，无法修改，请谨慎考虑。
    </div>

    <el-dialog
      :title="istype == 1 ? '邀请注册实名用户uid' : '邀请注册实名购买用户uid'"
      :visible.sync="viewListShow"
      width="25%"
    >
      <div class="viewListList">
        <div class="flex">
          <div
            style="
              width: 50%;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
            "
          >
            手机号
          </div>
          <div
            style="
              width: 50%;
              color: #ffffff;
              font-size: 20px;
              text-align: center;
            "
          >
            uid
          </div>
        </div>
        <div class="viewListList-ul" @scroll="handleScroll">
          <div
            v-for="(item, index) in assignmentList"
            :key="index"
            class="viewListList-ul-li flex"
          >
            <div class="viewListList-ul-li-div">
              {{ item.invitationUserTel }}
            </div>
            <div class="viewListList-ul-li-div">
              {{ item.invitationUserId }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    inviteTask: Array,
    breakShow: Boolean,
  },
  name: "",
  data() {
    return {
      superiorUserId: "",
      assignmentList: [],
      viewListShow: false,
      hasNextPage: false,
      page: {
        pageNum: 1,
        pageSize: 30,
      },
      istype: 1,
      number: {},
      superiors: false,
    };
  },
  mounted() {
    this.page.pageNum = 1;
    this.userInviteTask();
    this.mySuperior();
  },
  methods: {
    //复制
    copyTextButton(text, name) {
      if (!text) {
        this.$message.error(`复制内容不能为空`);
        return;
      }
      text = text.toString();
      this.$copyText(text)
        .then(() => {
          this.$message.success(`${name}复制成功`);
        })
        .catch(() => {
          this.$message.error(`${name}复制失败`);
        });
    },
    //
    drawdown() {
      if (!this.breakShow) {
        this.$emit("drawdownw");
      }
    },
    //绑定邀请人
    async confirmAddition() {
      if (!this.superiorUserId) return;
      const { data, code } = await this.$ajax.bindingSuperior({
        superiorUserId: this.superiorUserId,
      });
      this.mySuperior();
    },
    //查看上级
    async mySuperior() {
      const { data } = await this.$ajax.mySuperior();
      if (data) {
        this.superiors = true;
        this.superiorUserId = data;
      } else {
        this.superiors = false;
      }
    },
    setItemText1(num) {
      return () => {
        if (this.number.orderCount >= num) {
          return num + "/" + num;
        } else {
          return this.number.orderCount + "/" + num;
        }
      };
    },
    setItemText(num) {
      return () => {
        if (this.number.signCount >= num) {
          return num + "/" + num;
        } else {
          return this.number.signCount + "/" + num;
        }
      };
    },
    percentage1(num) {
      if (((100 / num) * this.number.orderCount).toFixed(0) * 1 > 100) {
        return 100;
      } else {
        return ((100 / num) * this.number.orderCount).toFixed(0) * 1;
      }
    },
    percentage(num) {
      if (((100 / num) * this.number.signCount).toFixed(0) * 1 > 100) {
        return 100;
      } else {
        return ((100 / num) * this.number.signCount).toFixed(0) * 1;
      }
    },
    //获取用户数量
    async userInviteTask() {
      const { data } = await this.$ajax.userInviteTask();
      this.number = data;
    },

    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        // 在这里执行你想要的操作
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.mySubordinates(this.istype, "button");
        }
      }
    },
    //查看实名下级
    async mySubordinates(type, button) {
      this.istype = type;
      const params = {
        ...this.page,
      };
      if (type == 1) {
        params.isSign = 1;
      } else {
        params.isOrder = 1;
      }
      const { data, code } = await this.$ajax.mySubordinates(params);
      if (code == 200) {
        this.viewListShow = true;
        this.hasNextPage = data.hasNextPage;
        if (button == "button") {
          this.assignmentList.push(...data.list);
        } else {
          this.assignmentList = data.list;
        }
        console.log(this.assignmentList);
      }
    },
  },
};
</script>
<style lang="scss">
.assignment {
  width: 100%;
  height: 100%;

  &-usernum {
    width: 1244px;
    height: 153px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/1244-153.png")
      no-repeat;
    background-size: 100% 100%;

    &-text {
      color: #ffffff;
      font-size: 24px;
      text-shadow: 2px 3px 2px #74077d;
      &-span {
        color: #daff00;
        text-shadow: 2px 3px 2px #74077d;
        font-size: 26px;
      }
    }

    &-button {
      width: 128px;
      height: 54px;
      flex-shrink: 0;
      border-radius: 10px;
      background: #9757c5;
      text-align: center;
      line-height: 54px;
      color: #ffffff;
      font-size: 22px;
    }
  }

  .el-progress-bar__innerText {
    width: 310px;
    font-size: 22px;
    display: flex;
    line-height: 30px;
    justify-content: center;
  }
  .el-progress-bar__inner {
    background: url("https://cdn.bubbleplan.cn/static/journalism/progress_bgc.png")
      no-repeat;
  }

  .assignment-bac {
    width: 611px;
    height: 210px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/addition.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 45px 0 0 37px;
    &-input {
      width: 356px;
      height: 66px;
    }
    .el-input__inner {
      width: 356px;
      height: 66px;
      border-radius: 10px;
      background: #9757c5;
      border: 0;
      color: #fff;
      font-size: 24px;
    }

    .el-input input::placeholder {
      color: #ffffffcc;
      font-size: 24px;
      line-height: 66px;
    }

    &-buton {
      width: 152px;
      height: 66px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #daff00;
      margin-left: 29px;
      color: #13212f;
      font-size: 23px;
      line-height: 66px;
      text-align: center;
    }
  }

  .assignment-bac1 {
    width: 611px;
    height: 210px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/addition1.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 45px 0 0 37px;
    margin-right: 19px;
    &-text {
      color: #ffffff;
      font-size: 24px;
      &-top {
        margin-top: 22px;
        &-text {
          border-radius: 10px;
          background: #9757c5;
          height: 66px;
          width: 261px;
          color: #ffffff;
          font-size: 24px;
          line-height: 66px;
          padding: 0px 0 0 22px;
          position: relative;
          &-img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .el-input__inner {
      width: 356px;
      height: 66px;
      border-radius: 10px;
      background: #9757c5;
      border: 0;
      color: #fff;
      font-size: 24px;
    }

    .el-input input::placeholder {
      color: #ffffffcc;
      font-size: 24px;
      line-height: 66px;
    }
  }

  &-ul {
    margin-top: 25px;
    &-li:hover {
      width: 1244px;
      height: 183px;
      background: url("https://cdn.bubbleplan.cn/static/home/bgc_cc.png")
        no-repeat;
      background-size: 100% 100%;
    }

    &-li {
      margin: auto;
      margin-bottom: 18px;
      width: 1244px;
      height: 183px;
      background: url("https://cdn.bubbleplan.cn/static/journalism/backgroundimage.png")
        no-repeat;
      background-size: 100% 100%;

      &-progressbar {
        width: 319px;
        height: 40px;
        border-radius: 4px;
        background: #fac0ff;
        padding: 5px 3px;
        margin-top: 22px;
      }

      &-border {
        width: 101px;
        height: 101px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #811c91;
        text-align: center;
        border: 6px solid #fac0ff;
        &-img {
          width: 84px;
          height: 70px;
          margin-top: -9px;
        }
        &-text {
          font-size: 20px;
          color: #ffffff;
        }
      }

      &-button {
        width: 152px;
        height: 56px;
        border-radius: 8px;
        background: #daff00;
        line-height: 56px;
        text-align: center;
        color: #13212f;
        font-size: 22px;
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button1 {
        width: 152px;
        height: 56px;
        border-radius: 8px;
        background: #627a1c;
        line-height: 56px;
        text-align: center;
        color: #13212f;
        font-size: 22px;
      }
    }
  }

  .lashin-title1 {
    margin: auto;
    margin-top: 62px;
    margin-bottom: 28px;
    width: 240px;
    height: 72px;
    text-align: center;
    line-height: 72px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/border_button.png")
      no-repeat;
    background-size: 100% 100%;
    color: #daff00;
    font-size: 24px;
  }

  .el-dialog__title {
    color: #daff00;
    font-size: 24px;
    font-weight: 400;
  }

  .el-dialog {
    border-radius: 20px;
    background: #13212f;
  }

  .el-dialog__close el-icon el-icon-close {
    color: #64849a;
    font-size: 20px;
  }

  .el-dialog__header {
    border-bottom: 2px solid #021521;
  }

  .viewListList {
    &-ul::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    &-ul {
      margin-top: 18px;
      height: 533px;
      overflow: auto;

      &-li {
        border-top: 1px solid #25465c;
        padding: 17px;

        &-div {
          color: #64849a;
          font-size: 20px;
          width: 50%;
          text-align: center;
        }
      }
    }
  }
  &-duplication {
    width: 66px;
    height: 66px;
    border-radius: 0 12px 12px 0;
    opacity: 1;
    background: #fac0ff;
    position: absolute;
    right: 0;
    top: 0;
  }
  .bound-text {
    color: #ffffffcc;
    font-size: 24px;
    margin-top: 34px;
    width: 266px;
    height: 66px;
    background: #9757c5;
    border-radius: 10px;
    line-height: 66px;
    padding: 0 23px;
  }
  .bound-title {
    color: #ffffffcc;
    font-size: 24px;
    line-height: 66px;
    margin-top: 34px;
    margin-right: 10px;
  }
}
</style>