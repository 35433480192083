<template>
  <div class="lashin flex flex-direction items-center">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="lashin-back"
      @click="back"
    />
    <div style="width: 100%; height: 100%">
      <assignment
        v-if="drawdown"
        :inviteTask="inviteTask"
        @drawdownw="drawdownw"
        :breakShow="breakShow"
      >
      </assignment>
      <task v-else></task>
    </div>
  </div>
</template>
<script>
import task from "./components/task.vue";
import assignment from "./components/assignment.vue";

export default {
  components: {
    assignment,
    task,
  },
  data() {
    return {
      drawdown: true,
      inviteTask: [],
      breakShow: false,
    };
  },
  mounted() {
    this.inviteTaskList();
  },
  methods: {
    back() {
      if (!this.drawdown) {
        this.drawdown = !this.drawdown;
        return;
      }
      history.back();
    },
    //
    drawdownw() {
      this.drawdown = false;
    },
    //任务
    async inviteTaskList() {
      const { data } = await this.$ajax.inviteTaskList();
      data.forEach((item, index) => {
        data[index] = {
          ...item,
          isState: false,
        };
      });
      this.inviteTask = data;

      for (let i = 0; i < this.inviteTask.length; i++) {
        if (this.inviteTask[i].isGet == 1) {
          this.breakShow = true; // 根据条件设置标志变量
          break;
        } else {
          this.breakShow = false;
        }
      }
    },
  },
};
</script>
<style lang="scss">
.lashin {
  width: 100%;
  min-height: calc(100vh - 94px);
  //   background: #9a13a5;
  background: url("https://cdn.bubbleplan.cn/static/home/bcg.png") no-repeat;
  background-size: 100% 100%;
  // padding-top: 168px;
  padding-top: 96px;
  position: relative;
  .lashin-back {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 30px;
    left: 16%;
  }
  .lashin-back {
    cursor: pointer;
  }
}
</style>