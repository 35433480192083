<template>
  <div class="task">
    <div class="lashin-title">战友招募</div>
    <div
      v-for="(item, index) in inviteTask"
      :key="index"
      class="task-li flex items-center flex-between"
      @mouseleave="handleMouseOver(index)"
      @mouseenter="handuleMouseenter(index)"
    >
      <div>
        邀请{{ item.inviteTask.signCount }}位用户注册实名，邀请{{
          item.inviteTask.orderCount
        }}位用户购买通行证，奖励{{ item.inviteTask.rewardNum }}芯片
      </div>
      <div
        :class="item.isState ? 'task-li-button1' : 'task-li-button'"
        @click="taskButton(item)"
      >
        确认领取
      </div>
    </div>
    <el-dialog title="" :visible.sync="secedeShow" width="26%">
      <div class="secedeClass">
        <div class="secedeClass-title">提示</div>
        <div class="secedeClass-text">
          <div>
            此活动任务，不可叠加，四选一领取奖励。一经领取，无法修改，请谨慎考虑。
          </div>
          <div class="flex flex-around secedeClass-button" style="">
            <div class="notarize" @click="secedeShow = !secedeShow">取 消</div>
            <div class="cancel" @click="secedeButton()">确 认</div>
          </div>
        </div>
        <!-- <span slot="footer" class="task-dialog-footer flex flex-between">
          <div class="notarize" @click="secedeShow = !secedeShow">取 消</div>
          <div class="cancel" @click="secedeButton()">确 认</div>
        </span> -->
      </div>
    </el-dialog>
    <el-dialog :visible.sync="awardShow" width="25%">
      <div class="awardShowClass">
        <div class="awardShowClass-title flex items-center flex-center">
          <div class="awardShowClass-title-text">奖励</div>
        </div>
        <div
          class="awardShowClass-border flex flex-direction items-center flex-center"
        >
          <img
            class="awardShowClass-img"
            src="https://cdn.bubbleplan.cn/static/journalism/chip.png"
            alt=""
          />
          <div style="color: #ffffff" class="awardShowClass-text">
            <span style="color: #ffffff; font-weight: 700">{{
              inviteTaskData
            }}</span
            >芯片
          </div>
        </div>
        <div class="awardShowClass-button" @click="awardShowButton()">好的</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      secedeShow: false,
      awardShow: false,
      taskId: "",
      inviteTaskData: null,
      inviteTask: [],
    };
  },
  mounted() {
    this.inviteTaskList();
  },
  methods: {
    //任务
    async inviteTaskList() {
      const { data } = await this.$ajax.inviteTaskList();
      data.forEach((item, index) => {
        data[index] = {
          ...item,
          isState: false,
        };
      });
      this.inviteTask = data;
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
        this.crystalNum();
      }
    },
    //查询晶体or晶体积分
    async crystalNum() {
      const { data, code } = await this.$ajax.crystalNum();
      if (code == 200) {
        this.usercrystalNumData(data);
      }
    },
    awardShowButton() {
      this.awardShow = false;
    },
    async secedeButton() {
      this.secedeShow = false;
      console.log(this.taskId);
      const { data, code } = await this.$ajax.inviteTaskGet({
        id: this.taskId,
      });
      if (code == 200) {
        this.awardShow = true;
        this.inviteTaskData = data;
        this.userNum();
      }
    },
    taskButton(item) {
      this.taskId = item.inviteTask.id;
      this.secedeShow = true;
    },

    handleMouseOver(index) {
      this.inviteTask[index].isState = false;
    },
    handuleMouseenter(index) {
      this.inviteTask[index].isState = true;
    },
  },
};
</script>
<style lang="scss">
.task {
  height: calc(100vh - 376px);
  .awardShowClass-text {
    font-size: 26px;
  }
  &-li {
    width: 1244px;
    height: 113px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/task.png")
      no-repeat;
    background-size: 100% 100%;
    margin: auto;
    margin-top: 14px;
    color: #ffffff;
    text-shadow: 2px 4px 4px #74077d;
    font-size: 24px;
    padding: 0 42px 0 51px;
  }

  &-li-button {
    width: 249px;
    height: 64px;
    text-align: center;
    line-height: 64px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/task_button.png")
      no-repeat;
    background-size: 100% 100%;
  }
  &-li-button1:hover {
    cursor: pointer;
  }
  &-li:hover {
    width: 1244px;
    height: 113px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/task_hover.png")
      no-repeat;
    background-size: 100% 100%;
    margin: auto;
    margin-top: 14px;
    padding: 0 42px 0 51px;
    color: #daff00;
    text-shadow: 2px 4px 4px #74077d;
    font-size: 24px;
  }

  &-li-button1 {
    width: 268px;
    height: 69px;
    text-align: center;
    line-height: 64px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/task_button.png")
      no-repeat;
    background-size: 100% 100%;
  }

  .el-dialog__title {
    color: #daff00;
    font-size: 24px;
    font-weight: 400;
  }

  .el-dialog {
    border-radius: 20px;
    background: #13212f;
  }

  .el-dialog__close el-icon el-icon-close {
    color: #64849a;
    font-size: 20px;
  }
  .el-icon {
    font-size: 20px;
  }
  .el-dialog__headerbtn {
    right: 10px;
  }
  .el-dialog__header {
    padding: 0;
    border-bottom: 0px solid #021521;
  }
  .el-dialog__body {
    padding: 0;
  }
  .secedeClass {
    width: 580px;
    height: 370px;
    flex-shrink: 0;
    border-radius: 20px;
    border: var(--sds-size-blur-100) solid #ec00ff;
    background: #8d0998;
    box-shadow: 0 10px 0 0 #56005e inset;
    padding: 25px 23px;
    .secedeClass-title {
      color: #ffffff;
      text-align: center;
      text-shadow: 3px 3px 0 #56005d;
      font-family: "Microsoft YaHei UI";
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 35.42px;
    }
    .secedeClass-text {
      //   width: 533px;
      height: 267px;
      flex-shrink: 0;
      border-radius: 15px;
      background: #f2e6f8;
      margin-top: 19px;
      padding: 48px 50px;
      color: #8d0998;
      font-size: 24px;
    }
    &-button {
      margin-top: 40px;
    }
    .notarize {
      width: 178px;
      height: 66px;
      line-height: 66px;
      flex-shrink: 0;
      border-radius: 8px;
      color: #93a3af;
      background: url("../../../img/home/cancel_ccc.png") no-repeat;
      background-size: 100% 100%;
      text-align: center;
    }
    .notarize:hover {
      cursor: pointer;
    }
    .cancel {
      cursor: pointer;
    }
    .cancel {
      width: 178px;
      height: 66px;
      line-height: 66px;
      flex-shrink: 0;
      border-radius: 8px;
      background: url("https://cdn.bubbleplan.cn/static/home/affirm_bacc.png")
        no-repeat;
      background-size: 100% 100%;
      color: #000000;
      text-align: center;
    }
  }

  .task-dialog-footer {
    text-align: center;
    line-height: 44px;
    font-size: 20px;
  }

  .awardShowClass {
    padding: 0 100px;
    padding-top: 20px;
    padding-bottom: 74px;
    &-img {
      width: 116px;
      height: 97px;
      margin-top: -19px;
    }
    &-title {
      width: 325.84px;
      height: 193.24px;
      text-align: center;
      line-height: 72px;
      background: url("https://cdn.bubbleplan.cn/static/home/Mask%20group2x.png")
        no-repeat;
      background-size: 100% 100%;
      color: #daff00;
      font-size: 24px;
      margin: auto;
      &-text {
        width: 257px;
        height: 72px;
        background: url("https://cdn.bubbleplan.cn/static/home/Vector%201532x.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 72px;
      }
    }

    &-border {
      width: 130px;
      height: 130px;
      flex-shrink: 0;
      border-radius: 13.76px;
      background: url("https://cdn.bubbleplan.cn/static/home/border_ww.png")
        no-repeat;
      background-size: 100% 100%;
      margin: auto;
      margin-top: 0px;
    }

    &-button {
      width: 202px;
      height: 54px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #daff00;
      margin: auto;
      margin-top: 80px;
      color: rgb(19, 33, 47);
      font-size: 22px;
      text-align: center;
      line-height: 54px;
    }
  }

  .lashin-title {
    margin: auto;
    margin-top: 32px;
    width: 240px;
    height: 72px;
    background: url("https://cdn.bubbleplan.cn/static/journalism/border_button.png")
      no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
    font-size: 22px;
    text-align: center;
    line-height: 72px;
  }
}
</style>